<template>
  <v-data-table
    :items="events"
    :headers="[
        admin || team ? { text: 'Wettkampftag', value: 'matchday', sortable: false } : undefined,
        { text: 'Beginn', value: 'startDate', sortable: false },
        { text: 'Liga', value: 'liga.shortName', sortable: false },
        !overview ? { text: 'Name', value: 'name', sortable: false } : undefined,
        { text: 'Mannschaften', value: 'mannschaften', sortable: false, align: 'center' },
        team ? { text: 'Neutralverzicht', value: 'neutralwaiver', sortable: false, align: 'center' } : undefined,
        { text: 'Punkte', value: 'punkte', sortable: false, align: 'center' },
        !overview ? { text: 'GP', value: 'gp', sortable: false, align: 'center' } : undefined,
        !overview ? { text: 'TP', value: 'tp', sortable: false, align: 'center' } : undefined,
        admin ? { text: 'Status', value: 'status', sortable: false, align: 'center' } : undefined,
        admin ? { text: 'Werten', value: 'werten', sortable: false, align: 'center' } : undefined,
        !overview ? { text: 'Hinweise', value: 'publicComment', sortable: false, align: 'center' } : undefined,
        team ? { text: 'Anmerkungen', value: 'internalComment', sortable: false, align: 'center' } : undefined,
        !overview ? { text: 'Halle', value: 'venue', sortable: false, align: 'center' } : undefined,
        admin ? { text: 'Bearbeiten', value: 'edit', sortable: false, align: 'center' } : undefined,
        admin ? { text: 'Löschen', value: 'delete', sortable: false, align: 'center' } : undefined,
        admin || team ? { text: 'Live-Eingabe', value: 'input', sortable: false, align: 'center' } : undefined,
        admin || team ? { text: 'Live-Anzeige', value: 'view', sortable: false, align: 'center' } : undefined,
        admin ? { text: 'Zuschauer', value: 'spectators', sortable: false, align: 'center' } : undefined,
        admin ? { text: 'Dokumente', value: 'attachments', sortable: false, align: 'center' } : undefined
      ].filter(h => !!h)"
    :search="filter"
    :mobile-breakpoint="0"
    :items-per-page="50"
    :item-class="(item) => ({'stbm2021bold': item.status === 'EventMovedOnline', 'stbm2021red': item.status === 'EventStarted'})"
    @click:row="row"
  >
    <template #item.matchday="{item}">
      <template v-if="item.matchday">
        {{ item.matchday.name }}<br>
        {{ item.matchday.startDate | dateformat('DD.MM.YYYY') }}
      </template>
    </template>
    <template #item.startDate="{item}">
      <i v-if="item.status === 'EventPostponed'"><span style="text-decoration:line-through;">{{ item.startDate | dateformat('DD.MM.YYYY') }}</span><br>wird verschoben,<br>Termin noch offen</i>
      <span v-else :inner-html.prop="item.startDate | dateformat('dddd<br>DD.MM.YYYY<br>HH:mm')" />
    </template>
    <template #item.mannschaften="{item}">
      <div v-if="admin" style="min-height:30px;" @click.stop="$emit('openteams', item._id)">
        <span :inner-html.prop="item.mannschaften.join('\n') | nl2br" style="text-transform: none; white-space: nowrap;"/>
      </div>
      <span v-else :inner-html.prop="item.mannschaften.join('\n') | nl2br" style="text-transform: none; white-space: nowrap;"/>
    </template>
    <template #item.neutralwaiver="{item}">
      <span
        :inner-html.prop="item.nv.map(n => n ? '√' : '-').join('\n') | nl2br"
        style="text-transform: none;"
      />
    </template>
    <template #item.punkte="{item}">
      <template v-if="item.mode === 'wk_score4'">
        <div v-for="(e, i) in item.ergebnisse" :key="`${item._id}-${i}`">
          <b v-if="e">{{ e.score }}</b>
        </div>
      </template>
      <template v-else>
        <div v-for="(e, i) in item.ergebnisse" :key="`${item._id}-${i}`">
          <b v-if="e">{{ e.final | float2 }}</b>
        </div>
      </template>
    </template>
    <template #item.gp="{item}">
      <div v-for="(e, i) in item.ergebnisse" :key="`${item._id}-${i}`">
        <template v-if="e">{{ e.gp }}</template>
      </div>
    </template>
    <template #item.tp="{item}">
      <div v-for="(e, i) in item.ergebnisse" :key="`${item._id}-${i}`">
        <template v-if="e">{{ e.tp }}</template>
      </div>
    </template>
    <template #item.status="{item}">
      <base-edit-dialog-select
        :value="item.status"
        :items="status"
        :id="item._id"
        @input="({value, id}) => $emit('changestatus', {id, value})"
      />
    </template>
    <template #item.werten="{item}">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            fab
            x-small
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>far fa-bolt</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="t in item.teams"
            :key="t.team._id"
            @click.stop="$emit('set12', {id: item._id, tid: t.team._id})"
          >
            <v-list-item-title>12:0 für <b>{{ t.team.name }}</b></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <template #item.publicComment="{item}">
      <v-tooltip
        v-if="item.publicComment"
        bottom
      >
        <template
          v-slot:activator="{ on }"
        >
          <v-btn
            v-on="on"
            small
            fab
            text
          >
            <v-icon>
              far fa-info-circle
            </v-icon>
          </v-btn>
        </template>
        <span>
            {{ item.publicComment }}
          </span>
      </v-tooltip>
    </template>
    <template #item.internalComment="{item}">
      <v-tooltip
        v-if="item.internalComment"
        bottom
      >
        <template
          v-slot:activator="{ on }"
        >
          <v-btn
            v-on="on"
            small
            fab
            text
          >
            <v-icon>
              far fa-info-circle
            </v-icon>
          </v-btn>
        </template>
        <span>
            {{ item.internalComment }}
          </span>
      </v-tooltip>
    </template>
    <template #item.venue="{item}">
      <v-tooltip
        v-if="item.venue"
        bottom
      >
        <template
          v-slot:activator="{ on }"
        >
          <v-btn
            v-on="on"
            small
            fab
            text
            @click.stop="openmaps(item.venue.address)"
          >
            <v-icon>
              far fa-circle-h
            </v-icon>
          </v-btn>
        </template>
        <span>
              {{ item.venue.name }}<br>
              {{ item.venue.address.streetAddress }} {{ item.venue.address.streetNumber }}<br>
              {{ item.venue.address.postalCode }} {{ item.venue.address.addressLocality }}
            </span>
      </v-tooltip>
    </template>
    <template #item.edit="{item}">
      <v-btn
        text
        small
        fab
        @click.stop="$emit('edit', item._id)"
      >
        <v-icon>
          far fa-pencil
        </v-icon>
      </v-btn>
    </template>
    <template #item.delete="{item}">
      <v-btn
        text
        small
        fab
        @click.stop="del(item._id)"
      >
        <v-icon>
          far fa-trash-alt
        </v-icon>
      </v-btn>
    </template>
    <template #item.input="{item}">
      <v-btn
        text
        fab
        small
        @click.stop="(ev) => $emit('input', item._id, ev)"
      >
          <span class="fa-stack fa-1x">
            <i
              class="far fa-play fa-stack-2x"
              style="margin-left:0px;margin-top:-6px;font-size:300%;"
            />
            <i
              class="far fa-pencil-alt fa-stack-1x"
              style="margin-left:4px;margin-top:1px;font-size:100%;text-shadow:0 0 2px white;"
            />
          </span>
      </v-btn>
    </template>
    <template #item.view="{item}">
      <v-btn
        text
        fab
        small
        @click.stop="(ev) => $emit('view', item._id, ev)"
      >
          <span class="fa-stack fa-1x">
            <i
              class="far fa-play fa-stack-2x"
              style="margin-left:0px;margin-top:-5px;font-size:300%;"
            />
            <i
              class="far fa-eye fa-stack-1x"
              style="margin-left:4px;font-size:100%;text-shadow:0 0 2px white;"
            />
          </span>
      </v-btn>
    </template>
    <template #item.spectators="{item}">
      <base-edit-dialog
        :value="(item.info || {}).zuschauer"
        :id="item._id"
        @input="({value, id}) => $emit('changespectators', {id, value})"
      />
    </template>
    <template #item.attachments="{item}">
      <v-menu
        v-if="item.info && item.info.attachments && item.info.attachments.length > 0"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
          >
            {{ item.info.attachments.length }}
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="(a, index) in item.info.attachments"
            :key="index"
          >
            <v-list-item-title>
              <a :href="`${imageBase}${a._id}`" :download="a.filename" target="_blank">{{ a.filename }}</a> ({{ a.mimetype }})
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-data-table>
</template>

<script>
import { EventStatus } from '@/enum'
import { GRAPHQLlocal, GRAPHQLserver } from '@/env'

export default {
  name: 'EventTable',

  props: {
    events: {
      type: Array
    },
    filter: {
      type: String,
      default: ''
    },
    admin: {
      type: Boolean,
      default: false
    },
    team: {
      type: Boolean,
      default: false
    },
    overview: {
      type: Boolean,
      default: false
    },
    open: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    status () {
      return EventStatus
    },
    imageBase () {
      if (!GRAPHQLlocal) return '/uploads/'

      const base = GRAPHQLserver.match(/(wss?):\/\/([^/]*)/)
      return `http${base[1] === 'wss' ? 's' : ''}://${base[2]}/uploads/`
    }
  },

  methods: {
    async del (id) {
      if (await this.$root.$children[0].$refs.confirm.open('Wirklich löschen?', 'Soll die Begegnung wirklich gelöscht werden? Dieser Vorgang kann nicht rückgängig gemacht werden!', { width: 400 })) {
        this.$emit('del', id)
      }
    },
    row (item, data, ev) {
      if (this.open) this.$openlink(`/view/${item._id}`, ev)
      else this.$emit('row', item, ev)
    },
    openmaps (address) {
      const a = `${address.streetAddress} ${address.streetNumber}, ${address.postalCode}, ${address.addressLocality}`
      window.open(`https://www.google.de/maps/place/${a.replaceAll(' ', '+')}`, '_blank')
    }
  }
}
</script>
